.advert-desktop {
  position: relative;
  margin-top: 30px;

  &.is-affixed {
    position: fixed !important;
    top: 0;
  }

  &__left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    left: auto;
    margin-left: -180px;
    position: absolute;
    top: 0;
    width: 160px;
    -webkit-transition: margin-top .3s;
    -o-transition: margin-top .3s;
    transition: margin-top .3s;
  }

  &__right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    left: auto;
    position: absolute;
    right: auto;
    top: 0;
    width: 160px;
    -webkit-transition: margin-top .3s;
    -o-transition: margin-top .3s;
    transition: margin-top .3s;

    @media screen and (min-width: 1024px) {
      margin-left: 1024px;
    }

    @media screen and (min-width: 1140px) {
      margin-left: 1140px;
    }

    @media screen and (min-width: 1400px) {
      margin-left: 1200px;
    }
  }
}
